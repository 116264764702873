export enum PassCodeError {
  invalid = 'invalid',
  expired = 'expired'
}

export interface WelcomePageProps {
  // This will change over time
  // Currently we have no idea how the response request will look like
  callback: (value: string) => Promise<string | void>;
}
