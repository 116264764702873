import React, { FC } from 'react';
import { SingleInput } from './digit-input.styled';
import { UseFormMethods } from 'react-hook-form';

interface DigitInputProps extends Pick<UseFormMethods, 'register'> {
  error: boolean;
  disabled: boolean;
  name: string;
}

export const DigitInput: FC<DigitInputProps> = ({
  register,
  name,
  disabled,
  error,
}) => {
  const onInput = (event) => {
    const { value, nextElementSibling } = event.target;

    if (value) {
      nextElementSibling?.focus();
    }
  };

  const onRemove = (event) => {
    const { value, previousElementSibling } = event.target;

    if (!value && ['Backspace', 'Delete'].includes(event.key)) {
      previousElementSibling?.focus();
    }
  };

  return (
    <SingleInput
      required
      autoComplete="off"
      name={name}
      ref={register}
      onInput={onInput}
      onKeyDown={onRemove}
      disabled={disabled}
      error={error}
    />
  );
};
