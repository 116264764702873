import { PassCodeError } from '../education-features-welcome-page.types';
import { TranslationKey } from '@nn-virtual-pen/education/data-access';
import { PASSWORD_LENGTH } from '../education-features-welcome-page.consts';

export const getErrorText = (errorType): TranslationKey => {
  if (errorType === PassCodeError.expired) {
    return TranslationKey.welcome_expired_passcode;
  }

  if (errorType === PassCodeError.invalid) {
    return TranslationKey.welcome_invalid_passcode;
  }

  return TranslationKey.welcome_enter_passcode;
};

export const stringifyPin = pin => {
  if (!Array.isArray(pin)) {
    return;
  }

  const stringified = pin.join('');

  if (stringified.length !== PASSWORD_LENGTH) {
    return;
  }

  return stringified;
};
