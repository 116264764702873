import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.introBg};
  min-height: 100%;
  padding: 55px 8px 38px;

  @media ${props => props.theme.media.desktop} {
    padding-top: 84px;
  }
`;

export const CodeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  @media ${props => props.theme.media.desktop} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 6px;
`;
