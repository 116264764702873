import React, { useRef } from 'react';
import { T, cond, prop } from 'ramda';
import { navigate } from 'gatsby';
import { WelcomePage } from '@nn-virtual-pen/education/features/welcome-page';
import { GenericLayout } from '../layouts/generic';
import { RoutePath } from '@nn-virtual-pen/education/utils';
import { Helmet } from 'react-helmet';
import { withUnauthenticated } from '../hoc';
import { Initialization } from '../components/initialization';

const Welcome = ({ actions }) => {
  const { current: callback } = useRef(
    async (value: string): Promise<string | void> => {
      await actions.authenticatePassCode(value);
      await navigate(RoutePath.Configure);
    }
  );

  return (
    <GenericLayout>
      <Helmet title="Welcome" />
      <WelcomePage callback={callback} />
    </GenericLayout>
  );
};

export default withUnauthenticated(
  cond([
    [prop('isLoading'), Initialization],
    [T, Welcome],
  ])
);
