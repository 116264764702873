import * as yup from 'yup';
import { PASSWORD_LENGTH } from './education-features-welcome-page.consts';

export const schema = yup.object().shape({
  pin: yup
    .array()
    .min(PASSWORD_LENGTH)
    .max(PASSWORD_LENGTH)
    .required()
    .ensure(),
});
