import React from 'react';
import { getErrorText } from '../helpers';
import * as Styled from './styled.styled';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';

const StatusContent = ({ isSubmitting, errorType }) => {
  const { t } = useTranslate();

  if (isSubmitting) {
    return (
      <Styled.PendingWrapper>
        <Styled.PendingIcon /> {t(TranslationKey.welcome_validating_passcode)}
      </Styled.PendingWrapper>
    );
  }

  if (errorType) {
    return (
      <Styled.ErrorWrapper>
        <Styled.AlertIcon /> {t(getErrorText(errorType))}
      </Styled.ErrorWrapper>
    );
  }

  return null;
};

export const Status = ({ isSubmitting, errorType }) => {
  return (
    <Styled.StatusWrapper>
      <StatusContent isSubmitting={isSubmitting} errorType={errorType} />
    </Styled.StatusWrapper>
  );
};
