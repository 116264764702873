import styled from 'styled-components';
import { Info } from '@styled-icons/material-twotone/Info';
import { Pending } from '@styled-icons/material-twotone/Pending';

export const AlertIcon = styled(Info)`
  transform: rotate(180deg);
  height: 14px;
`;

export const PendingIcon = styled(Pending)`
  height: 14px;
`;

export const ErrorWrapper = styled.div`
  color: ${(props) => props.theme.palette.error};
`;

export const PendingWrapper = styled.div`
  color: ${(props) => props.theme.palette.nnBlue};
`;

export const StatusWrapper = styled.div`
  padding-top: 5px;
  height: 30px;
  font-size: 14px;
  line-height: 20px;
`;
