import React, { FC } from 'react';
import { Title } from './title';
import { DigitInput } from './digit-input';
import { Status } from './status';
import { usePasscode } from './education-features-welcome-page.hook';
import * as Styled from './education-features-welcome-page.styled';
import {
  BlurredCircle as StyledBlurredCircle,
  BlurredCirclePosition,
  Label,
} from '@nn-virtual-pen/education/ui';
import {
  Logo,
  LogoSize
} from '@nn-virtual-pen/ui';
import { WelcomePageProps } from './education-features-welcome-page.types';
import welcome from 'static/animations/welcome.json';
import Lottie from 'react-lottie-player';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';
import { PASSWORD_LENGTH } from './education-features-welcome-page.consts';

export const WelcomePage: FC<WelcomePageProps> = ({ callback }) => {
  const { t } = useTranslate();

  const {
    register,
    errorType,
    handleSubmit,
    isSubmitting,
    onSubmit,
  } = usePasscode(callback);

  return (
    <Styled.Wrapper>
      <StyledBlurredCircle position={BlurredCirclePosition.bottomRight} />
      <div>
        <Title />
        <Styled.ContentWrapper>
          <Label align="left">{t(TranslationKey.welcome_passcode)}</Label>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Styled.CodeWrapper>
              {[...new Array(PASSWORD_LENGTH)].map((_, index) => (
                <DigitInput
                  // eslint-disable-next-line react/no-array-index-key
                  key={`pin[${index}]`}
                  name={`pin[${index}]`}
                  register={register}
                  disabled={isSubmitting}
                  error={!!errorType}
                />
              ))}
            </Styled.CodeWrapper>
          </form>
          <Status
            isSubmitting={isSubmitting}
            errorType={errorType} />
          <Lottie
            loop={false}
            speed={0.35}
            animationData={welcome}
            play
          />
        </Styled.ContentWrapper>
      </div>
      <Styled.LogoWrapper>
        <Logo size={LogoSize.normal} />
      </Styled.LogoWrapper>
    </Styled.Wrapper>
  );
};
