import React from 'react';
import * as Styled from './title.styled';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';

export const Title = () => {
  const { t } = useTranslate();

  return (
    <Styled.Wrapper>
      <Styled.UnderHeaderLine>{t(TranslationKey.welcome_text_1)}</Styled.UnderHeaderLine>
      <Styled.HeaderLine>{t(TranslationKey.welcome_text_2)}</Styled.HeaderLine>
    </Styled.Wrapper>
  );
};
