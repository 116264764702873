import styled from 'styled-components';

interface SingleInputProps {
  error?: boolean;
  disabled: boolean;
}

export const SingleInput = styled.input.attrs({
  type: 'number',
  min: '0',
  inputmode: 'numeric',
  pattern: '[0-9]*',
  title: 'Non-negative integral number'
})<SingleInputProps>`
  border: 2px solid ${props => props.theme.palette.nnBlue};
  border-radius: 6px;
  font-size: 30px;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.palette.nnBlue};
  width: 40px;
  height: 40px;
  text-align: center;
  outline: 0;
  transition: border-color 200ms ease-in-out;
  padding: 0;

  &:focus,
  &:active {
    border-color: ${props => props.theme.palette.lightBlue};
    outline: 0;
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  &:valid {
    background: ${props => props.theme.palette.white};
    border-color: ${props => props.theme.palette.nnBlue};
  }

  &:disabled {
    opacity: 0.4;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ${props => props.error ? `border-color: ${props.theme.palette.error} !important;` : ''}

  @media ${(props) => props.theme.media.desktop} {
    width: 62px;
    height: 62px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;
