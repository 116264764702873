import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';
import { useYupValidationResolver } from '@nn-virtual-pen/utils';
import { schema } from './education-features-welcome-page.schema';
import { stringifyPin } from './helpers';
import { PASSWORD_LENGTH } from './education-features-welcome-page.consts';

export const usePasscode = (callback) => {
  const { current: validationSchema } = useRef(schema);
  const resolver = useYupValidationResolver(validationSchema);

  const {
    handleSubmit,
    register,
    watch,
    errors,
    clearErrors,
    setError,
    formState,
  } = useForm({
    resolver,
    defaultValues: {
      pin: [...new Array(PASSWORD_LENGTH)],
    },
  });

  const pin = watch('pin').join('');

  const errorType = errors?.pin?.type;

  const onSubmit = useCallback(async ({ pin }): Promise<void> => {
    const candidate = stringifyPin(pin);

    if (!candidate) {
      return;
    }

    let newErrorType;

    try {
      await callback(candidate);
    } catch (errorType) {
      newErrorType = errorType;
    }

    if (!newErrorType) {
      return;
    }

    setError('pin', { type: newErrorType });
  }, [setError]);

  useEffect(
    () => {
      const fetch = async () => {
        await handleSubmit(onSubmit)(null);
      };

      if (errorType) {
        clearErrors();
      }

      if (pin.length !== PASSWORD_LENGTH) {
        return;
      }

      fetch();
    },
    [pin, clearErrors, handleSubmit, onSubmit]
  );

  return {
    register,
    errorType,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    onSubmit,
  };
};
