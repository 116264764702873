import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${props => props.theme.palette.nnBlue};
  text-align: center;
  margin-bottom: 60px;

  @media ${props => props.theme.media.desktop} {
    margin-bottom: 84px;
  }
`;

export const UnderHeaderLine = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: ${props => props.theme.palette.nnBlue};
`;

export const HeaderLine = styled.h1`
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 36px;
  line-height: 49px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.palette.nnBlue};
  margin: 0;
`;
